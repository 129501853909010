<template>
  <div>
    <XyeaFormEdit
      icon="gesture-tap"
      :url="url"
      :entityName="entityName"
      :form="form"
      :id="form.actionId"
      @close="$emit('close')"
      @add="add"
      @update="refresh"
      :disabled="readonly"
      :dialog="true"
      :loadOnOpen="false"
      ref="formEdit"
    >
      <b-field label="Action" horizontal>
        <b-input v-model="form.description" type="textarea" required :disabled="readonly" v-focus />
      </b-field>
      <b-field label="Role" horizontal>
        <RoleSelect v-model="form.roleId" required :disabled="readonly" />
        <SelectLocation
          v-if="!this.isResidentAction"
          v-model="form.locationId"
          label="Location"
          required
          :disabled="readonly"
          notFullWidth
          :locationName="form.locationName"
        />
      </b-field>
      <DateTimeSelector label="Due Date" v-model="form.dueDate" required customPosition="is-over5" :disabled="readonly" :future="true" />
      <b-field label="Status" horizontal>
        <span :class="statusColour"> {{ statusDesc }}</span>
        <b-field v-if="closed" label="By" horizontal> {{ form.who }} on {{ shortFormat(form.modified) }} </b-field>
      </b-field>
      <ValidationProvider :rules="{ actionNotesRequired: notesRequired }" v-slot="{ errors }">
        <b-field v-if="form.actionId" label="Notes" horizontal :type="{ 'is-danger': errors[0] }" :message="errors">
          <b-input v-model="form.notes" type="textarea" :disabled="readonly" :required="notesRequired" />
        </b-field>
      </ValidationProvider>
      <AttachmentsCard v-if="form.actionId" :entityId="form.actionId" :attachmentType="attachmentType" :readonly="readonly" />
      <p v-else class="mt-7" />
      <template v-slot:extraButtons="{ buttonProps }" v-if="canClose">
        <b-button class="button is-primary" :loading="buttonProps.isSaving" @click="notDone">Not Done</b-button>
        <b-button class="button is-primary" :loading="buttonProps.isSaving" @click="complete">Complete</b-button>
      </template>
    </XyeaFormEdit>
  </div>
</template>

<script>
import XyeaFormEdit from "@/components/XyeaFormEdit";
import PermissionsEnum from "@/enums/permissions";
import DateTimeSelector from "@/components/DateTimeSelector.vue";
import SelectLocation from "@/components/SelectLocation.vue";
import RoleSelect from "@/components/RoleSelect.vue";
import ActionStatus from "@/enums/actionStatus.js";
import AttachmentsCard from "@/components/AttachmentsCard.vue";
import Attachments from "@/enums/imageType";
import Dates from "@/common/dates.js";

import ActionType from "@/enums/actionType.js";

export default {
  components: { XyeaFormEdit, DateTimeSelector, SelectLocation, RoleSelect, AttachmentsCard },
  props: ["action", "risk", "residentId", "actionType"],

  data() {
    return {
      isLoading: false,
      attachmentType: Attachments.Type.ActionAttachment,
      form: this.getClearFormObject(),
      notesRequired: false
    };
  },
  computed: {
    readonly() {
      return !this.$store.getters.hasPermission(PermissionsEnum.RiskUpdate) || this.closed;
    },
    canClose() {
      return (
        this.$store.getters.hasPermission(PermissionsEnum.RiskUpdate) &&
        !this.closed &&
        // have to save before we can close, makes adding action simpiler
        this.form.actionId
      );
    },
    isResidentAction() {
      return this.actionType === ActionType.Enum.CarePlan;
    },
    url() {
      return (this.isResidentAction ? "residentactions" : "riskactions") + "/";
    },
    entityName() {
      return this.isResidentAction ? "Resident Action" : "Risk Action";
    },
    closed() {
      return this.form.status === ActionStatus.Enum.Complete && this.form.actionId > 0;
    },
    statusDesc() {
      return ActionStatus.Lookup[this.form.status];
    },
    statusColour() {
      return ActionStatus.Colour[this.form.status];
    }
  },
  watch: {
    "form.dueDate": {
      handler(dueDate) {
        if (this.form.status !== ActionStatus.Enum.Complete) {
          this.form.status = dueDate < Dates.now() ? ActionStatus.Enum.Overdue : ActionStatus.Enum.Pending;
        }
      }
    }
  },
  methods: {
    getClearFormObject() {
      return {
        riskId: this.risk?.riskId,
        residentId: this.residentId,
        description: null,
        locationId: this.risk?.locationId,
        roleId: this.risk?.roleId,
        dueDate: null,
        notes: null,
        status: ActionStatus.Enum.Pending,
        actionType: ActionType.Enum.Risk
      };
    },
    add() {
      this.refresh();
    },
    refresh() {
      this.$emit("refresh");
    },
    complete() {
      this.form.status = ActionStatus.Enum.Complete;
      this.notesRequired = false;
      this.$nextTick(() => this.$refs.formEdit.submitForm());
    },
    notDone() {
      this.form.status = ActionStatus.Enum.NotDone;
      this.notesRequired = true;
      this.$nextTick(() => this.$refs.formEdit.submitForm());
    },
    shortFormat(date) {
      return Dates.shortFormat(date);
    }
  },
  mounted() {
    if (this.action?.actionId) {
      this.form = { ...this.action }; // note we copy the passed in details, rather than assign, as otherwise changes in the dialog would be immediately reflected back
      this.form.riskId = this.risk.riskId;
      this.form.dueDate = Dates.dateOrNull(this.action.dueDateLocal); // Use the local date
    }
  }
};
</script>
