<template>
  <div class="scrollPaneFooter ratingFooter centredFooter" :class="{ asideVisible: isAsideMobileExpanded }">
    <div class="invertRatingColour" :class="colour">
      <div class="level is-mobile">
        <div class="level-item has-text-centered">
          <h3 class="subtitle is-spaced has-text-centered" :class="colour">
            {{ label }}
          </h3>
        </div>
        <div class="level-item has-text-centered">
          <h1 class="title has-text-centered" :class="colour">
            <growing-number :value="number" :prefix="prefix" :suffix="suffix" />
          </h1>
        </div>
        <div class="level-item has-text-centered">
          <b-icon class="ratingIcon" :class="colour" :icon="icon" size="is-medium" />
        </div>
        <div class="level-item has-text-centered">
          <h3 class="subtitle is-spaced" :class="colour">{{ riskName }}</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GrowingNumber from "@/components/GrowingNumber";
import AssessmentType from "@/enums/assessmentType";
import { mapState } from "vuex";

export default {
  name: "CardWidget",
  components: { GrowingNumber },
  props: {
    number: { type: Number, default: 0 },
    prefix: { type: String, default: null },
    suffix: { type: String, default: null },
    label: { type: String, default: null },
    rating: { type: Number, default: null },
    riskType: { type: Number, default: AssessmentType.RiskTypeEnum.Standard4 }
  },
  computed: {
    riskName() {
      return AssessmentType.riskRatingDesc(this.riskType, this.rating);
    },
    icon() {
      return AssessmentType.riskIcon(this.riskType, this.rating);
    },
    colour() {
      return AssessmentType.riskColour(this.riskType, this.rating);
    },
    ...mapState(["isAsideMobileExpanded"])
  },
  mounted() {
    this.$store.dispatch("setStickyRatingVisible", true);
  },
  beforeDestroy() {
    this.$store.dispatch("setStickyRatingVisible", false);
  }
};
</script>

<style>
.ratingFooter {
  bottom: 70px;
  background: inherit;
}
.ratingFooter > div {
  border-radius: 30px;
  padding: 10px;
}
.stickyRatingMargin {
  margin-top: 45px;
}
.ratingIcon {
  top: 1px;
  position: relative;
}
</style>
