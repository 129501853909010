<template>
  <div>
    <XyeaFormEdit
      icon="gamepad"
      url="controls/"
      :entityName="entityName"
      :form="form"
      :id="form.controlId"
      @close="$emit('close')"
      @add="v => $emit('added', v)"
      @update="v => $emit('updated', v)"
      :disabled="readonly"
      :dialog="true"
      :loadOnOpen="false"
      @selectOrgControl="selectOrgControl"
    >
      <b-field v-if="viewOrgControl" label="Organisational" horizontal>
        <b-checkbox v-model="form.organisational" :disabled="orgControlReadonly" />
      </b-field>
      <b-field label="Description" horizontal>
        <b-input v-model="form.description" type="textarea" required :disabled="readonly" v-focus />
      </b-field>

      <AttachmentsCard v-if="form.controlId" :entityId="form.controlId" :attachmentType="attachmentType" :readonly="readonly" />

      <template v-slot:extraLeftButtons="{ buttonProps }" v-if="canSelectOrgControl">
        <div class="modal-card-footer">
          <b-button class="button is-primary" :loading="buttonProps.isSaving" @click="() => buttonProps.close('selectOrgControl')"
            >Select Organisational Control</b-button
          >
        </div>
      </template>
    </XyeaFormEdit>
  </div>
</template>

<script>
import XyeaFormEdit from "@/components/XyeaFormEdit";
import AttachmentsCard from "@/components/AttachmentsCard.vue";
import Attachments from "@/enums/imageType";
import PermissionsEnum from "@/enums/permissions";

export default {
  components: { XyeaFormEdit, AttachmentsCard },
  props: {
    control: { type: Object },
    riskId: { type: Number },
    orgControl: { type: Boolean }
  },

  data() {
    return {
      isLoading: false,
      attachmentType: Attachments.Type.ControlAttachment,
      form: this.getClearFormObject()
    };
  },
  computed: {
    adminOrgControls() {
      return this.$store.getters.hasPermission(PermissionsEnum.AdminOrgControls);
    },
    noUpdatePermission() {
      return !this.$store.getters.hasPermission(PermissionsEnum.RiskUpdate);
    },
    canSelectOrgControl() {
      return this.form.riskId && !this.form.controlId;
    },
    readonly() {
      return this.noUpdatePermission || (this.form.organisational && !this.adminOrgControls);
    },
    orgControlReadonly() {
      // readonly also if not admin, no Risk, or for existing control for Risk (as if we switched it from org to not org, would need to check any linked risks)
      return this.noUpdatePermission || !this.adminOrgControls || !this.riskId || (this.riskId > 0 && this.form.controlId > 0);
    },
    viewOrgControl() {
      // View if set, or for admin on new control
      return this.form.organisational || (this.adminOrgControls && !this.form.controlId);
    },
    entityName() {
      return (this.form.organisational ? "Organisational " : "") + "Control";
    }
  },
  methods: {
    getClearFormObject() {
      return {
        controlId: 0,
        riskId: this.riskId,
        organisational: this.orgControl,
        description: null
      };
    },
    selectOrgControl() {
      this.$emit("close");
      this.$emit("selectOrgControl");
    }
  },
  mounted() {
    if (this.control?.controlId) {
      this.form = { ...this.control };
    }
  }
};
</script>
