<template>
  <XyeaFormEdit
    icon="shield-alert"
    url="risks/"
    entityName="Risk"
    backRoute="risks"
    :form="form"
    :formPostData="formPostData"
    :id="form.riskId"
    @mapServerData="mapServerData"
    :canSave="!readonly"
    @dataSaved="dataSaved"
  >
    <div class="columns">
      <div class="column">
        <NameField label="Title" v-model="form.title" required :disabled="readonly" v-focus />
      </div>
      <div class="column">
        <b-field label="Role" horizontal>
          <RoleSelect v-model="form.roleId" required :disabled="readonly" />
          <SelectLocation v-model="form.locationId" label="Location" required :disabled="readonly" notFullWidth />
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <NotesField label="Description" v-model="form.description" required :disabled="readonly" />
      </div>
      <div class="column">
        <NotesField label="Consequence" v-model="form.consequence" :disabled="readonly" />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-field label="Category" horizontal>
          <LookupSelect
            :lookupType="lookupRiskCategory"
            v-model="form.categoryId"
            required
            placeholder="Select primary category"
            :disabled="readonly"
          />
        </b-field>
      </div>
      <div class="column">
        <b-field label="Secondary" horizontal>
          <LookupSelect
            :lookupType="lookupRiskCategory"
            secondary
            :parentId="form.categoryId"
            v-model="form.category2Id"
            placeholder="Select secondary category"
            :disabled="readonly"
          />
        </b-field>
      </div>
    </div>

    <div class="columns">
      <CollapseCard
        name="Control"
        title="Controls"
        :open.sync="controlsOpen"
        :showAdd="!readonly"
        @add="addControl"
        :headerIcons="controlHeaderIcons"
        @header-icons-click="headerIconClicked"
        class="primaryCard"
      >
        <ContextTable
          smallNoData
          :isLoading="isLoadingControls"
          v-model="selectedControl"
          :tableData="controls"
          :columns="controlColumns"
          :canEdit="!readonly"
          @contextDefaultClicked="editControl"
        />
      </CollapseCard>
      <div class="column">
        <CardComponent title="Rating" class="primaryCard">
          <b-field label="Likelihood" horizontal>
            <enum-drop-down v-model="form.likelihood" :data="selectLikelihood" required :disabled="readonly" />
          </b-field>
          <b-field label="Impact" horizontal>
            <enum-drop-down v-model="form.impact" :data="selectImpact" required :disabled="readonly" />
          </b-field>
        </CardComponent>
      </div>
    </div>

    <ScoreRating :number="score" label="Score" :rating="rating" :riskType="riskType" />

    <div class="columns">
      <div class="column">
        <b-field label="Next Review Date" horizontal class="label-long">
          <DateSelector v-model="form.nextReviewDate" required :disabled="readonly" :future="true" />
        </b-field>
      </div>
      <CollapseCard name="Review" title="Previous Reviews" :open.sync="reviewsOpen" :showAdd="!readonly" @add="addReview">
        <b-table :data="riskReviews" :loading="isLoadingReviews" smallNoData default-sort="modified" default-sort-direction="desc">
          <b-table-column label="Review Date" field="modified" sortable v-slot="props">
            {{ shortFormat(props.row.modified) }}
          </b-table-column>
          <b-table-column label="By" field="who" sortable v-slot="props">
            {{ props.row.who }}
          </b-table-column>
          <b-table-column label="Review" field="review" sortable v-slot="props">
            {{ props.row.review }}
          </b-table-column>

          <b-table-column custom-key="actions" v-slot="props">
            <DeleteObject
              v-if="isAdmin"
              entity="Risk Review"
              :deleteName="deleteName(props.row)"
              :deleteId="props.row.riskReviewId"
              url="riskreviews"
              @deleted="deletedRiskReview"
            />
          </b-table-column>
        </b-table>
      </CollapseCard>
    </div>

    <div class="columns">
      <CollapseCard name="Action" title="Actions" :open.sync="actionsOpen" :showAdd="!readonly" @add="addAction">
        <b-tabs v-model="actionsTab" position="is-centered">
          <b-tab-item label="Open">
            <ContextTable
              hideNoData
              noDataMsg="No Open Actions for this Risk"
              :isLoading="isLoadingActions"
              v-model="selectedAction"
              :tableData="openActions"
              :canEdit="!readonly"
              @contextDefaultClicked="editAction"
              :row-class="setActionColour"
              sortField="dueDate"
              sortOrder="asc"
            >
              <b-table-column label="Due" field="dueDate" sortable v-slot="props">
                {{ shortFormat(props.row.dueDate) }}
              </b-table-column>
              <b-table-column label="Action" field="description" sortable v-slot="props">
                {{ props.row.description }}
              </b-table-column>
              <b-table-column label="Location" field="locationName" sortable v-slot="props">
                {{ props.row.locationName }}
              </b-table-column>
              <b-table-column label="Role" field="roleName" sortable v-slot="props">
                {{ props.row.roleName }}
              </b-table-column>
              <b-table-column label="Status" field="status" sortable v-slot="props">
                {{ props.row.statusDesc }}
              </b-table-column>

              <b-table-column custom-key="actions" v-slot="props">
                <DeleteObject
                  v-if="isAdmin"
                  entity="Action"
                  :deleteName="deleteActionName(props.row)"
                  :deleteId="props.row.actionId"
                  url="riskactions"
                  @deleted="deletedAction"
                />
              </b-table-column>
            </ContextTable>
          </b-tab-item>

          <b-tab-item label="Closed">
            <ContextTable
              hideNoData
              noDataMsg="No Closed Actions for this Risk"
              :isLoading="isLoadingActions"
              v-model="selectedAction"
              :tableData="closedActions"
              :canEdit="!readonly"
              @contextDefaultClicked="editAction"
              sortField="modified"
              sortOrder="desc"
            >
              <b-table-column label="Closed" field="modified" sortable v-slot="props">
                {{ shortFormat(props.row.modified) }}
              </b-table-column>
              <b-table-column label="By" field="who" sortable v-slot="props">
                {{ props.row.who }}
              </b-table-column>
              <b-table-column label="Action" field="description" sortable v-slot="props">
                {{ props.row.description }}
              </b-table-column>
              <b-table-column label="Location" field="locationName" sortable v-slot="props">
                {{ props.row.locationName }}
              </b-table-column>
              <b-table-column label="Role" field="roleName" sortable v-slot="props">
                {{ props.row.roleName }}
              </b-table-column>

              <b-table-column custom-key="actions" v-slot="props">
                <DeleteObject
                  v-if="isAdmin"
                  entity="Action"
                  :deleteName="deleteActionName(props.row)"
                  :deleteId="props.row.actionId"
                  url="riskactions"
                  @deleted="deletedAction"
                />
              </b-table-column>
            </ContextTable>
          </b-tab-item>
        </b-tabs>
      </CollapseCard>

      <AttachmentsCard :entityId="form.riskId" :attachmentType="attachmentType" :readonly="readonly" />
    </div>
  </XyeaFormEdit>
</template>

<script>
import XyeaFormEdit from "@/components/XyeaFormEdit";
import NameField from "@/components/NameField";
import NotesField from "@/components/NotesField";
import SelectLocation from "@/components/SelectLocation.vue";
import LookupSelect from "@/components/LookupSelect.vue";
import EnumDropDown from "@/components/EnumDropDown";
import RiskEnum from "@/enums/risk";
import ScoreRating from "@/components/ScoreRating";
import RoleSelect from "@/components/RoleSelect";
import DateSelector from "@/components/DateSelector.vue";
import RiskReviewDialog from "@/components/dialogs/RiskReviewDialog.vue";
import ActionDialog from "@/components/dialogs/ActionDialog.vue";
import ControlDialog from "@/components/dialogs/ControlDialog.vue";
import SelectOrgControlDialog from "@/components/dialogs/SelectOrgControlDialog.vue";
import CollapseCard from "@/components/CollapseCard";
import ContextTable from "@/components/ContextTable";
import DeleteObject from "@/components/DeleteObject.vue";

import { mapGetters } from "vuex";
import PermissionsEnum from "@/enums/permissions";
import AssessmentType from "@/enums/assessmentType";
import Attachments from "@/enums/imageType";
import DatesMixin from "@/mixins/datesMixin.js";
import AttachmentsCard from "@/components/AttachmentsCard.vue";
import CheckDirty from "@/mixins/checkDirty";
import CardComponent from "../components/CardComponent.vue";
import ActionStatus from "@/enums/actionStatus.js";
import ActionType from "@/enums/actionType.js";
import saveIfNew from "@/mixins/saveIfNewMixin.js";

export default {
  components: {
    XyeaFormEdit,
    NameField,
    NotesField,
    SelectLocation,
    LookupSelect,
    EnumDropDown,
    ScoreRating,
    RoleSelect,
    DateSelector,
    AttachmentsCard,
    CollapseCard,
    ContextTable,
    DeleteObject,
    CardComponent
  },
  mixins: [DatesMixin, CheckDirty, saveIfNew],
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      lookupRiskCategory: LookupSelect.Enum.RiskCategory,
      selectLikelihood: RiskEnum.SelectLikelihood,
      selectImpact: RiskEnum.SelectImpact,
      attachmentType: Attachments.Type.RiskAttachment,
      riskType: AssessmentType.RiskTypeEnum.Standard3,
      locationNameReadOnly: null,
      reviewsOpen: false,
      isLoadingReviews: false,
      riskReviews: [],

      controlsOpen: true,
      isLoadingControls: false,
      controls: [],
      selectedControl: {},
      controlHeaderIcons: [{ icon: "office-building-marker", name: "sel-org-control", title: "Select Organisational Control" }],
      controlColumns: [{ field: "description", label: "Description", sortable: true }],

      actionsOpen: false,
      isLoadingActions: false,
      actions: [],
      selectedAction: {},
      actionsColumns: [{ field: "description", label: "Description", sortable: true }],
      actionsTab: 0,

      form: {
        riskId: this.id ?? 0,
        locationId: null,
        roleId: null,
        title: null,
        description: null,
        consequence: null,
        categoryId: null,
        category2Id: null,
        likelihood: null,
        impact: null,
        nextReviewDate: null
      }
    };
  },
  computed: {
    ...mapGetters(["isAdmin", "hasPermission"]),
    readonly() {
      return !this.hasPermission(PermissionsEnum.RiskUpdate);
    },
    score() {
      return this.form.likelihood * this.form.impact;
    },
    rating() {
      const score = this.score;
      if (score < 6) return RiskEnum.Enum.LowRisk;
      if (score < 15) return RiskEnum.Enum.MediumRisk;
      return RiskEnum.Enum.HighRisk;
    },

    closedActions() {
      return this.actions.filter(a => a.status === ActionStatus.Enum.Complete);
    },
    openActions() {
      return this.actions.filter(a => a.status !== ActionStatus.Enum.Complete);
    }
  },
  watch: {
    reviewsOpen(open) {
      if (open && !this.riskReviews.length) this.refreshReviews();
    },
    actionsOpen(open) {
      if (open && !this.actions.length) this.refreshActions();
    },
    "form.riskId": {
      immediate: true,
      handler(open) {
        if (open && !this.controls.length) this.refreshControls();
      }
    }
  },
  methods: {
    validateForm() {
      return true;
    },

    mapServerData(data) {
      this.form = data; // map top level params
      this.form.nextReviewDate = this.dateOrNull(data.nextReviewDate);

      this.setNotDirty();
    },
    formPostData() {
      var postData = { ...this.form };
      postData.nextReviewDate = this.formatYYYYMMDD(this.form.nextReviewDate);

      // for a new control we post list of control Ids
      if (!this.riskId) {
        postData.newControlIds = this.controls.map(c => c.controlId);
      }
      return postData;
    },

    addReview() {
      this.saveIfNew(() => this.form.riskId, "reviews").then(() => {
        this.reviewsOpen = true;
        this.$buefy.modal.open({
          parent: this,
          component: RiskReviewDialog,
          hasModalCard: true,
          props: { riskId: this.form.riskId, nextReviewDate: this.form.nextReviewDate },
          events: { refresh: this.refreshReviews, setNextReviewDate: this.setNextReviewDate }
        });
      });
    },
    setNextReviewDate(date) {
      this.form.nextReviewDate = date;
    },
    refreshReviews() {
      this.isLoadingReviews = true;
      this.$http
        .get("riskreviews/" + this.form.riskId)
        .then(r => (this.riskReviews = r.data))
        .catch(e => this.$alerts.showErrorAlert(e, "Error retrieving Risk Reviews"))
        .finally(() => (this.isLoadingReviews = false));
    },
    deletedRiskReview(id) {
      this.riskReviews = this.riskReviews.filter(r => r.riskReviewId !== id);
    },
    deleteName(row) {
      return "Risk Review on " + this.shortFormat(row.modified) + " by " + row.who;
    },

    addControl() {
      this.selectedControl = {};
      this.editControl();
    },
    editControl() {
      this.controlsOpen = true;
      this.$buefy.modal.open({
        parent: this,
        component: ControlDialog,
        hasModalCard: true,
        props: { riskId: this.form.riskId, control: this.selectedControl },
        events: { added: this.addedControl, updated: this.updatedControl, selectOrgControl: this.selectOrgControl }
      });
    },
    addedControl(newControl) {
      if (!this.form.riskId) {
        this.controls.push(newControl);
      } else {
        this.refreshControls();
      }
    },
    updatedControl(control) {
      var match = this.controls.find(c => c.controlId === control.controlId);
      if (match) {
        match.description = control.description;
      } else {
        this.refreshControls();
      }
    },
    refreshControls() {
      this.isLoadingControls = true;
      this.$http
        .get("controls/forRisk/" + this.form.riskId)
        .then(r => (this.controls = r.data))
        .catch(e => this.$alerts.showErrorAlert(e, "Error retrieving Controls"))
        .finally(() => (this.isLoadingControls = false));
    },
    selectOrgControl() {
      this.$buefy.modal.open({
        parent: this,
        component: SelectOrgControlDialog,
        hasModalCard: true,
        props: { riskId: this.form.riskId },
        events: { added: this.addedControl }
      });
    },
    headerIconClicked(iconName) {
      switch (iconName) {
        case "sel-org-control":
          this.selectOrgControl();
      }
    },

    addAction() {
      this.selectedAction = {};
      this.editAction();
    },
    editAction() {
      if (!this.form.riskId) {
        return this.$alerts.showAlert("Record not saved", "Please save Risk first before adding actions");
      }
      this.reviewsOpen = true;
      this.$buefy.modal.open({
        parent: this,
        component: ActionDialog,
        hasModalCard: true,
        props: { risk: this.form, action: this.selectedAction, actionType: ActionType.Enum.Risk },
        events: { refresh: this.refreshActions }
      });
    },
    refreshActions() {
      if (this.isLoadingActions) return;
      this.isLoadingActions = true;
      this.actionsOpen = true;
      const search = { riskId: this.form.riskId };
      this.$http
        .get("riskActions", { params: search })
        .then(r => (this.actions = r.data.tableData))
        .catch(e => this.$alerts.showErrorAlert(e, "Error retrieving Actinos"))
        .finally(() => (this.isLoadingActions = false));
    },
    deleteActionName(row) {
      return "Action modified by " + this.shortFormat(row.modified) + " by " + row.who;
    },
    deletedAction(id) {
      this.actions = this.actions.filter(r => r.actionId !== id);
    },
    setActionColour(row) {
      return ActionStatus.Colour[row.status];
    }
  }
};
</script>
