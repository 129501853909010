<template>
  <div>
    <XyeaFormEdit
      icon="shield-search"
      url="riskreviews/"
      entityName="Risk Review"
      :form="form"
      :id="form.riskReviewId"
      @close="$emit('close')"
      @add="add"
      @update="refresh"
      :disabled="readonly"
      :dialog="true"
      :loadOnOpen="false"
    >
      <b-field label="Risk Review" horizontal class="label-flex2">
        <b-input v-model="form.review" type="textarea" required :disabled="readonly" v-focus />
      </b-field>
      <b-field label="Next Review Date" horizontal class="label-flex2">
        <DateSelector v-model="dlgNextReviewDate" required customPosition="is-over4" :future="true" />
      </b-field>
    </XyeaFormEdit>
  </div>
</template>

<script>
import XyeaFormEdit from "@/components/XyeaFormEdit";
import PermissionsEnum from "@/enums/permissions";
import DateSelector from "@/components/DateSelector.vue";

export default {
  components: { XyeaFormEdit, DateSelector },
  props: ["riskReviews", "riskId", "nextReviewDate"],

  data() {
    return {
      isLoading: false,
      dlgNextReviewDate: this.nextReviewDate,
      form: this.getClearFormObject()
    };
  },
  computed: {
    readonly() {
      return !this.$store.getters.hasPermission(PermissionsEnum.RiskUpdate);
    }
  },
  methods: {
    getClearFormObject() {
      return {
        riskId: this.riskId,
        review: null
      };
    },
    add() {
      this.$emit("setNextReviewDate", this.dlgNextReviewDate);
      this.refresh();
    },
    refresh() {
      this.$emit("refresh");
    }
  }
};
</script>
