<template>
  <b-select placeholder="Select Role" :value="value" @input="v => $emit('input', v)" :disabled="disabled" :required="required" expanded>
    <option v-for="option in allRoles" :value="option.id" :key="option.id">
      {{ option.name }}
    </option>
  </b-select>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    value: { type: Number },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    includeAll: { type: Boolean, default: false },
    editTopLevel: { type: Number, default: 100 } // if not disabled, then only show roles up to this level
  },
  computed: {
    ...mapGetters("roles", ["roles"]),
    allRoles() {
      var roles = this.roles;
      if (!this.disabled) roles = this.roles.filter(r => r.level <= this.editTopLevel);
      if (this.includeAll) roles.unshift({ id: -1, name: "<All Roles>" });
      return roles;
    }
  },
  data() {
    return {
      isLoading: false
    };
  },
  async created() {
    if (!this.roles.length) {
      await this.$store.dispatch("roles/getRoles");
    }
  }
};
</script>
