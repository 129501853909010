<template>
  <b-field :label="label" horizontal>
    <b-input
      :value="value"
      @input="v => $emit('input', v)"
      :placeholder="placeholder"
      maxlength="100"
      :disabled="disabled"
      :required="required"
      :hasCounter="hasCounter"
    />
  </b-field>
</template>

<script>
export default {
  props: {
    value: { type: String },
    placeholder: { type: String },
    disabled: { type: Boolean },
    required: { type: Boolean },
    label: { type: String },
    hasCounter: { type: Boolean }
  }
};
</script>
