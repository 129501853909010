<template>
  <div>
    <div class="box">
      <div class="columns">
        <div class="column" :class="{ 'is-4': !selectOnly }">
          <b-field horizontal label="Search">
            <b-field>
              <b-input v-model="search.description" />
              <ClearSearch @clearSearch="clearSearch" />
            </b-field>
          </b-field>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <ContextTableCard
          :hideHeader="selectOnly"
          :title="selectOnly ? '' : 'Organisational Controls'"
          icon="gamepad"
          :header-icons="headerIcons"
          :tableData="tableData"
          :sortField="search.sortField"
          :sortOrder="search.sortOrder"
          :page="search.page"
          :total="total"
          @refreshData="debounceRefreshData"
          @page-change="pageChange"
          @sort="sort"
          v-model="selectedControl"
          @headerIconClicked="headerIconClicked"
          @contextDefaultClicked="defaultClicked"
          :menuOptions="contextOptions"
          @menu-clicked="contextMenuClicked"
          :isLoading="isLoading"
        >
          <b-table-column label="Description" field="Description" sortable v-slot="props">
            {{ props.row.description }}
          </b-table-column>
        </ContextTableCard>
      </div>
    </div>
  </div>
</template>

<script>
import ContextTableCard from "@/components/ContextTableCard.vue";
import ContextTableMixin from "@/mixins/contextTableMixin";
import ControlDialog from "@/components/dialogs/ControlDialog.vue";
import ClearSearch from "@/components/ClearSearch.vue";

import { mapMutations } from "vuex";
import IconsMixin from "@/mixins/iconsMixin.js";

export default {
  components: { ContextTableCard, ClearSearch },
  props: { selectOnly: { type: Boolean }, excludeRiskId: { type: Number } },
  mixins: [ContextTableMixin],
  data() {
    return {
      url: "controls",
      entity: "Organisational Controls",
      headerIcons: [IconsMixin.headerIconAdd("Control"), IconsMixin.headerIconRefresh()],
      selectedControl: {},
      search: {
        description: this.$store.state.controls.search.description,
        orgControlsOnly: true,
        page: 1,
        sortField: "description",
        sortOrder: "asc",
        excludeRiskId: this.excludeRiskId
      }
    };
  },
  watch: {
    "search.description": {
      handler() {
        this.debounceRefreshData();
        this.setSearch(this.search);
      }
    },
    "selectedControl.controlId": {
      handler() {
        this.$emit("controlId", this.selectedControl.controlId);
      }
    }
  },
  computed: {
    contextOptions() {
      var options = this.selectOnly ? [{ text: "Add to Risk", event: "select-control" }] : [{ text: "Edit", event: "edit-control" }];
      return options;
    }
  },
  methods: {
    ...mapMutations("controls", ["setSearch"]),
    async headerIconClicked(iconName) {
      switch (iconName) {
        case "add":
          this.addControl();
          break;
      }
    },
    defaultClicked() {
      this.selectOnly ? this.$emit("selectedControl") : this.editControl();
    },
    addControl() {
      this.selectedControl = {};
      this.editControl();
    },
    editControl() {
      this.controlsOpen = true;
      this.$buefy.modal.open({
        parent: this,
        component: ControlDialog,
        hasModalCard: true,
        props: { orgControl: true, control: this.selectedControl },
        events: { added: this.debounceRefreshData, updated: this.debounceRefreshData }
      });
    },
    contextMenuClicked(event) {
      switch (event) {
        case "edit-control":
          this.editControl();
          break;
        case "select-control":
          this.$emit("selectedControl");
          break;
      }
    },
    clearSearch() {
      this.search.description = null;
    }
  }
};
</script>
