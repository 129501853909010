<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title"><b-icon icon="gamepad" /> Select Organisational Control</p>
      <button type="button" class="delete" @click="close" />
    </header>
    <section class="modal-card-body">
      <OrgControlsList :selectOnly="true" @controlId="v => (selectedControlId = v)" @selectedControl="addToRisk" :excludeRiskId="riskId" />
    </section>
    <footer class="modal-card-foot">
      <nav class="level">
        <div class="level-left" />
        <div class="level-right">
          <button class="level-item button" type="button" @click="close">Cancel</button>
          <b-button class="level-item button is-primary" :loading="isSaving" @click="addToRisk" :disabled="!selectedControlId"
            >Add to Risk</b-button
          >
        </div>
      </nav>
    </footer>
  </div>
</template>

<script>
import OrgControlsList from "@/components/OrgControlsList.vue";

export default {
  components: { OrgControlsList },
  props: { riskId: { type: Number, required: true } },
  data() {
    return {
      selectedControlId: null,
      isSaving: false
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    addToRisk() {
      if (!this.selectedControlId) return;
      this.isSaving = true;
      this.$http
        .post(`controls/addToRisk/${this.riskId}/${this.selectedControlId}`)
        .then(() => {
          this.close();
          this.$emit("added");
        })
        .catch(e => this.$alerts.showErrorAlert(e, "Error saving selected Control"))
        .finally(() => (this.isSaving = false));
    }
  }
};
</script>
